import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Room from "../components/rooms/roomSummary";
import RoomPrices from "../components/rooms/roomPrices";
import {
  bar,
  roomPrices,
  roomsContent,
  roomsHeader,
  videoTour,
} from "../css/rooms.module.css";
import ReactMarkdown from "react-markdown";

const RoomPage = ({ data }) => (
  <>
    <Helmet>
      <title>Our Rooms | Pannal Village Hall</title>
    </Helmet>
    <div className={roomsHeader}>
      <h1>Our rooms</h1>
      <p>Book a room with us for your event</p>
    </div>

    <div className={roomsContent}>
      <figure className={videoTour}>
        <video
          controls
          poster="https://pannal-village-hall-public-waefkmmqxhaksmjj.s3.eu-west-2.amazonaws.com/Pannal+Village+Hall+-+Brief+Tour+of+the+Facilities+(thumbnail).jpeg"
        >
          <source
            src="https://pannal-village-hall-public-waefkmmqxhaksmjj.s3.eu-west-2.amazonaws.com/Pannal+Village+Hall+-+Brief+Tour+of+the+Facilities.webm"
            type="video/webm"
          />
          <source
            src="https://pannal-village-hall-public-waefkmmqxhaksmjj.s3.eu-west-2.amazonaws.com/Pannal+Village+Hall+-+Brief+Tour+of+the+Facilities.mp4"
            type="video/mp4"
          />
          <p>
            Your browser doesn’t support HTML video. Here is a{" "}
            <a href="https://pannal-village-hall-public-waefkmmqxhaksmjj.s3.eu-west-2.amazonaws.com/Pannal+Village+Hall+-+Brief+Tour+of+the+Facilities.mp4">
              link to the video
            </a>{" "}
            instead.
          </p>
        </video>
        <figcaption>
          A <b>short</b> video tour of our facilities
        </figcaption>
      </figure>
      <div className={roomPrices}>
        <div>
          <RoomPrices rooms={data.allStrapiRoom.edges.map((r) => r.node)} />
        </div>
      </div>
      {data.allStrapiRoom.edges.map((room) => (
        <Room details={room.node} key={room.node.id} />
      ))}
      <section className={bar}>
        <div>
          <h2>The Bar</h2>
          <ReactMarkdown source={data.strapiBarInfo.description} />
        </div>
      </section>
    </div>
  </>
);

export default RoomPage;

export const query = graphql`
  {
    allStrapiRoom(sort: { fields: roomOrder, order: ASC }) {
      edges {
        node {
          id
          slug
          RoomTitle
          roomstext
          altBookingInfo
          roomStatus
          prices {
            bookingType {
              name
            }
            cost
          }
          feature {
            roomFeatures {
              id
              feature
              featureImage {
                childImageSharp {
                  gatsbyImageData(width: 30, height: 30, layout: FIXED)
                }
              }
            }
          }
          bookOnline
          roomImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    strapiBarInfo {
      description
    }
  }
`;
