import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  altBookInfo,
  roomBook,
  roomFeatures,
  roomSummary,
  summaryInfo,
  summaryImage,
  summaryContainer,
} from "../../css/roomsummary.module.css";

const RoomSummary = ({ details }) => {
  const {
    RoomTitle,
    roomstext,
    roomImage,
    slug,
    bookOnline,
    altBookingInfo,
    roomStatus,
    feature,
  } = details;
  return (
    <div className={summaryContainer}>
      <div className={summaryImage}>
        <GatsbyImage
          image={roomImage.childImageSharp.gatsbyImageData}
          alt={RoomTitle}
          imgStyle={{ objectFit: "cover" }}
          style={{ height: "100%", width: "100%" }}
        />
      </div>
      <div className={summaryInfo}>
        <div className={roomSummary}>
          <h2>{RoomTitle}</h2>
          <p>{roomstext}</p>
          <Link to={slug}>View more details</Link>
        </div>
        <div className={roomFeatures}>
          <ul>
            {feature.roomFeatures.map((item) => (
              <li key={item.id}>
                <div>
                  <GatsbyImage
                    image={item.featureImage.childImageSharp.gatsbyImageData}
                    alt={item.feature}
                    style={{ marginRight: "0.4rem" }}
                  />
                  <div>{item.feature}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className={roomBook}>
          {bookOnline ? (
            <Link to={"/contact"} className="btn-primary">
              Contact us to book
            </Link>
          ) : (
            <div className={altBookInfo}>
              <p>{roomStatus}</p>
              <p>{altBookingInfo}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoomSummary;
