import React from "react";

const roomToRow = (room) => {
  const priceRow = (name) => {
    const x = room.prices.find((p) => p.bookingType.name === name);
    return x ? <td>{"£" + x.cost}</td> : x;
  };
  const priceRows = ["Regular Events", "Community", "Commercial"].map(priceRow);
  return priceRows.every((x) => x == null) ? null : (
    <tr>
      <th scope="row">{room.RoomTitle}</th>
      <>{priceRows}</>
    </tr>
  );
};

const RoomPrices = ({ rooms }) => {
  return (
    <table>
      <caption>Room prices (per hour)</caption>
      <thead>
        <tr>
          <td></td>
          <th scope="col">Regular Events</th>
          <th scope="col">Community</th>
          <th scope="col">Commercial</th>
        </tr>
      </thead>
      <tbody>
        {rooms.map(roomToRow)}
        <tr>
          <th scope="row">Main Hall + Function Room</th>
          <td>£25</td>
          <td>£35</td>
          <td>£45</td>
        </tr>
      </tbody>
    </table>
  );
};

export default RoomPrices;
